function EntryModel() {
    return {
        name: "",
        phone: "",
        email: "",
        address: "",
        birthday: "",
        school: "",
        grade: "",
        igsStudent: true,
    }
}

export default EntryModel